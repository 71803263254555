<template>
  <div>
    <v-card
      v-if="loading"
      class="mx-auto mt-5 mb-5"
      elevation="4"
      max-width="600"
    >
      <v-card-title class="justify-center">{{ video.title }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div
          style="
            position: relative;
            height: 100%;
            min-height: 400px;
            width: 100%;
            background-color: #000;
          "
        >
          <div
            style="
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            "
          >
            <v-progress-circular
              :size="120"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </div>
        <p class="pt-4" v-html="video.description"></p>
        <v-divider></v-divider>
      </v-card-text>
    </v-card>
    <v-card
      v-else-if="allowed"
      class="mx-auto mt-5 mb-5"
      elevation="4"
      max-width="600"
    >
      <v-card-title class="justify-center">{{ video.title }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div
          style="
            position: relative;
            height: 100%;
            min-height: 400px;
            width: 100%;
            background-color: #000;
          "
        >
          <iframe
            v-if="!loading"
            :src="video.cf_embed"
            style="
              border: none;
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
            "
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
            allowfullscreen="true"
          ></iframe>
        </div>
        <p class="pt-4" v-html="video.description"></p>
        <v-divider></v-divider>
      </v-card-text>
    </v-card>
    <v-card v-else class="mx-auto mt-5 mb-5" elevation="4" max-width="600">
      <v-card-title class="justify-center">{{ video.title }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <p class="pt-4">We are sorry, you do not have access to this video</p>
        <v-divider></v-divider>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import hasuraService from "@/services/hasura.service.js";

export default {
  name: "VideoPlayer",
  mixins: [hasuraService],

  computed: {
    allowed() {
      if (!this.video.oto_required) {
        return true;
      }
      // oto is required at this point
      if (
        this.$store.state.user.seminar_control_training ||
        this.$store.state.user.acl == 10
      ) {
        return true;
      }
      return false;
    },
  },

  async mounted() {
    this.video = await this.getVideo(this.$route.params.id);
    // TODO Simplify this logic
    this.loading = false;
  },

  data() {
    return {
      loading: true,
      video: {
        title: "Loading...",
        description: "Loading...",
        created_at: "Loading...",
        cf_id: "",
        oto_required: true,
      },
    };
  },
};
</script>
